<template>
    <parent-layout title="User Edit">
        <extended-form
            ref="form"
            :form-ready="form.ready"
            :form-data="form.data"
            :submit="submit"
            :disabled="!hasAccessRole || getFetching || getSaving"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <text-field :form="form" field-key="firstName" label="First Name" required />
                    <text-field :form="form" field-key="lastName" label="Last Name" required />
                    <text-field
                        :form="form"
                        field-key="emailAddress"
                        label="Email Address"
                        required
                    />
                    {{ /* key is to rerender to reset rules for internal / external based on roles input */ }}
                    <text-field
                        :key="'client_name_' + !userIsInternal"
                        :form="form"
                        field-key="clientName"
                        label="Client Name"
                        :required="!userIsInternal"
                    />
                    {{ /* key is to rerender to reset rules for internal / external based on roles input */ }}
                    <text-field
                        :key="'client_id_' + !userIsInternal"
                        :form="form"
                        field-key="clientId"
                        label="Client Id"
                        :required="!userIsInternal"
                    />
                    {{ /* key is to rerender to reset rules for internal / external based on roles input */ }}
                    <text-field
                        :key="'account_id_' + !userIsInternal"
                        :form="form"
                        field-key="accountId"
                        label="Account Id"
                        :required="!userIsInternal"
                    />

                    <v-autocomplete
                        v-model="form.data.roles"
                        :items="roleItems"
                        item-text="name"
                        item-value="id"
                        label="Select Roles"
                        solo-inverted
                        multiple
                        return-object
                        chips
                        hide-selected
                        deletable-chips
                        small-chips
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </extended-form>
    </parent-layout>
</template>

<script>
import { ROLE_USER_ROLE_EDIT, ROLE_INTERNAL_USER } from '@/helpers/security/roles'
import ExtendedForm from '@/components/form/ExtendedForm'
import { mapGetters, mapActions } from 'vuex'
import TextField from '@/components/form/TextField'
import ParentLayout from '@/components/layout/ParentLayout'
import { formSnackbar, errorSnackbar } from '@/helpers/snackbar'

/**
 * This user form is for managing internal console users. Console maintains it's own SSO system to allow a seporate context from other authentication.
 * This feature is intended to allow internal users to context switch within console without impacting their normal SSO session.
 */
export default {
    name: 'ConsoleUserForm',
    components: {
        ParentLayout,
        TextField,
        ExtendedForm,
    },
    data: function () {
        return {
            valid: false,
            form: {
                data: {
                    emailAddress: null,
                    accountId: null,
                    clientId: null,
                    firstName: null,
                    lastName: null,
                    clientName: null,
                    roles: [],
                },
                rules: {
                    emailAddress: [(v) => !!v || 'Email is required'],
                    firstName: [(v) => !!v || 'First Name is required'],
                    lastName: [(v) => !!v || 'last Name is required'],
                    clientName: [(v) => !!v || this.userIsInternal || 'Client Name is required'],
                    accountId: [(v) => !!v || this.userIsInternal || 'Account Id is required'],
                    clientId: [(v) => !!v || this.userIsInternal || 'Client Id is required'],
                },
                errors: [],
                ready: false,
            },
            allRoles: [],
        }
    },
    computed: {
        ...mapGetters({
            roleItems: 'role/getItems',
            hasRole: 'auth/hasRole',
            getFetching: 'user/getFetching',
            getSaving: 'user/getSaving',
            getItemById: 'user/getItemById',
            getDistrictId: 'getDistrictId',
            getIlluminateClientId: 'getIlluminateClientId',
        }),
        getParentRoute() {
            const parentRouteName = this.$route.meta.breadcrumb

            return this.$router.resolve({
                params: { districtId: this.getDistrictId },
                name: parentRouteName,
            })
        },
        getData() {
            return this.getItemById(this.getRouteId, 'id')
        },
        getRouteId() {
            return this.$route.params.id
        },
        hasAccessRole() {
            return this.hasRole(ROLE_USER_ROLE_EDIT)
        },
        userIsInternal() {
            return !!this.form.data?.roles.find((v) => v.role === ROLE_INTERNAL_USER)
        },
    },
    async mounted() {
        await this.fetchRoles({})
        await this.fetchUser(this.getRouteId)

        const formData = {}
        const user = this.getData
        if (!user) {
            const subtext = `Local user could not be found by account id ${this.getRouteId}.`
            await errorSnackbar({ subtext })

            await this.$router.push(this.getParentRoute.href)
            return
        }

        Object.keys(this.form.data).forEach((key) => {
            if (key === 'roles') {
                formData[key] = user['roleCollection']
                return
            }

            formData[key] = user[key]
        })

        this.setFormData(formData)
    },
    methods: {
        ...mapActions({
            fetchUser: 'user/getById',
            fetchRoles: 'role/cget',
            patchUser: 'user/patch',
        }),
        async submit() {
            const data = {
                ...this.form.data,
            }
            data.roles = this.form.data.roles.map((role) => {
                return role.id
            })

            const id = this.getData.id
            const resp = await this.patchUser({ id, data })
            const identifier = resp.data?.user?.emailAddress

            if (identifier) {
                await formSnackbar({
                    isEdit: true,
                    type: 'User',
                    identifier,
                })
            }

            await this.$router.push(this.getParentRoute.href)
        },
        setFormData(formData) {
            this.form.data = formData
            this.form.ready = true
        },
    },
}
</script>
